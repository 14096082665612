@font-face {
  font-family: 'Aeonik';
  src: url('/fonts/Aeonik/AeonikPro-Bold.woff');
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('/fonts/Aeonik/AeonikPro-Medium.woff');
  font-style: medium;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('/fonts/Aeonik/AeonikPro-Regular.woff');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('/fonts/Aeonik/AeonikPro-Light.woff');
  font-style: medium;
  font-weight: 300;
  font-display: swap;
}
